import 'typeface-source-code-pro'
import 'typeface-eb-garamond'
import 'typeface-fauna-one'
import 'typeface-fanwood-text'
import 'typeface-prata'
import '@openfonts/jost_latin'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './src/css/style.css'
// import { Parallax, ParallaxLayer } from "./src/components/parallax"
import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'

export const wrapRootElement = ({ element }) => {
  // return <Parallax pages={4}>{element}</Parallax>
  return <ParallaxProvider>{element}</ParallaxProvider>
}
